<template><div>
  <v-dialog v-model="dialogWorkflowNotifications" :persistent="true">
    <template v-slot:activator="{ props }">
      <v-badge
        v-bind="props"
        :color="workflowNotificationCount > 0 ? 'error' : 'primary'"
        size="small"
        max="9"
        :content="workflowNotificationCount"
      >
        <v-icon>mdi-message-badge-outline</v-icon>
      </v-badge>
    </template>
    <v-card>
      <v-card-title class="text-h5 text-primary">Workflow Notifications</v-card-title>
      <v-card-text>
        <v-data-table
          density="compact"
          v-model:page="page"
          :headers="headers"
          :items="workflowNotifications"
          :items-per-page="itemsPerPage"
          item-value="WorkflowNotificationID"
          >
          <template v-slot:item.WorkflowTypeID="{ item }">
            <v-icon
              :icon="getWorkflowTypeIcon(item.WorkflowTypeID)"
              color="primary"
            ></v-icon>
            {{ item.WorkflowTypeName }}
          </template>
        
          <template v-slot:item.WorkflowStageID="{ item }">
            {{ item.WorkflowStageName }}
          </template>
          <template v-slot:item.AssignedUserID="{ item }">
            {{ item.AssignedUserName }}
          </template>
          <template v-slot:item.DueDate="{ item }">
            {{ formattedDate(item.DueDate) }}
            <v-btn
              v-if="isOverdue(item.DueDate)"
              variant="text"
              size="m"
              color="error"
              icon=""
            ><v-icon icon="mdi-alert" />
              <v-tooltip
                activator="parent"
                location="top"
              >This workflow is overdue
              </v-tooltip>
            </v-btn>
          </template>
          <template v-slot:item.actions="{ item }">
          <div>
            <v-btn
              color="primary"
              icon=""
              variant="plain"
              density="compact"
              @click="navToWorkflow(item.WorkflowPageName, item.WorkflowNotificationID)"
            ><v-icon size="sm" icon="mdi-file-document-arrow-right" />
              <v-tooltip
                activator="parent"
                location="top"
              >Open workflow
              </v-tooltip>
            </v-btn>
            <v-btn
              v-if="item.AssignedUserID == null"
              color="primary"
              icon=""
              variant="plain"
              density="compact"
              @click="assignWorkflow(item.WorkflowNotificationID)"
            ><v-icon size="sm" icon="mdi-clipboard-account" />
              <v-tooltip
                activator="parent"
                location="top"
              >Assign to me
              </v-tooltip>
            </v-btn>
          </div>
        </template>
          <template v-slot:bottom>
            <div class="text-center pt-2">
              <v-pagination v-if="pageCount > 1"
                density="compact"
                variant="plain"
                color="primary"
                v-model="page"
                :length="pageCount"
              ></v-pagination>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" variant="text" @click="closeDialogWorkflowNotifications()">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div></template>
<script setup>
import { ref, watch, onMounted, computed } from 'vue'
import authService from '@/services/authService'
import utils from '@/services/utils'
import { useDate } from 'vuetify'
import { useRouter } from 'vue-router'

const props = defineProps({
  updateMessage: Function,
})

const router = useRouter()
const dateAdapter = useDate()
const userID = localStorage.getItem("user_id")

const page = ref(1)
const itemsPerPage = ref(10)
const pageCount = computed(() => {
  return Math.ceil(workflowNotifications.value.length / itemsPerPage.value)
})
const headers = [
  { title: "Type", key: "WorkflowTypeID", value: "WorkflowTypeID"},
  { title: "Summary", key: "Summary", value: "Summary"},
  { title: "Assigned User", key: "AssignedUserID", value: "AssignedUserID" },
  { title: "Due Date", key: "DueDate", value: "DueDate" },
  { title: "Workflow Stage", key: "WorkflowStageID", value: "WorkflowStageID" },
  { title: "Actions", key: "actions", width: "90px", sortable: false },
]

var workflowNotifications = ref([])
const workflowNotificationCount = ref(0)
const dialogWorkflowNotifications = ref(false)

watch(workflowNotifications, (newValue) => {
  workflowNotificationCount.value = newValue.length
})

function updateResultMessage(message) {
  props.updateMessage(message)
}

function getWorkflowTypeIcon(WorkflowTypeID) {
  switch(WorkflowTypeID) {
    case 1:
      return "mdi-account-plus"
    case 2:
      return "mdi-account-plus"
    case 3:
      return "mdi-account-plus"
    case 4:
      return "mdi-account-minus"
    case 5:
      return "mdi-account-minus"
    case 6:
      return "mdi-account-minus"
  }
}

function isOverdue(selectedDate) {
  const today = new Date()
  const dueDate = new Date(selectedDate)
  return (dueDate <= today)
}

function formattedDate(selectedDate) {
  return utils.formatDatePeriod(selectedDate, true)
}

function navToWorkflow(workflowPageName, workflowNotificationID) {
  router.push({
      name: workflowPageName,
      params: { id: workflowNotificationID },
  })
  closeDialogWorkflowNotifications()
}

async function assignWorkflow(workflowNotificationID) {
  try {
    let payload = { UserID: userID }
    await utils.httpRequest(`/Workflows/${workflowNotificationID}/Assign`, utils.REQUEST.POST, (data) => {
        if (data.Error) {
          throw new Error(data.Error.Message)
        }
      },
      payload
    )
    updateResultMessage("success|Assign Workflow|Successfully assigned to you")
    await getWorkflowNotifications()
    return
  } catch (e) {
    updateResultMessage("warning|" + e.message + "|" + "Assign Workflow " + workflowNotificationID)
  }
}

async function getWorkflowNotifications() {
  await utils.httpRequest(`/Notifications/${userID}`, utils.REQUEST.GET, (data) => {
      workflowNotifications.value = data
  })
}

function closeDialogWorkflowNotifications() {
  dialogWorkflowNotifications.value = false
}

onMounted(async () => {
  if (userID) {
    await getWorkflowNotifications()
    setInterval(getWorkflowNotifications, 10000)
  }
})
</script>
